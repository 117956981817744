.Skeleton {
    position: relative;
    opacity: 0.85;
    animation-duration: 1.8s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: #eef0f3;
    background: linear-gradient(to right, #eef0f3 8%, #e6e9ee 38%, #eef0f3 54%);
    background-size: 1000px 640px;

    @keyframes placeHolderShimmer {
        0% {
            background-position: -468px 0;
        }

        100% {
            background-position: 468px 0;
        }
    }
}
