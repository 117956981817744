.PropertyAdvancedSearchMobile {
    padding: 0.5rem 0.5rem;
    background: white;
    border-bottom: 1px solid rgb(222, 227, 231);

    > form {
        > :first-child {
            background: #eff6fc;
            border: 1px solid #e5f3f7;
        }
    }

    :global {
        .rc-select .rc-select-selection__choice {
            margin-top: 9px;
            line-height: 32px;
            font-weight: 500;
            background-color: #fff;
            border: 1px solid #e5f3f7;
        }

        .advanced-search-select {
            border-radius: 0.25rem;

            .rc-select-selection {
                border: none;
                border-style: none;
                border-color: transparent;
                border-radius: 0.25rem;
                line-height: 32px;
            }

            .rc-select-enabled .rc-select-selection:hover {
                border-color: unset;
                box-shadow: none;
            }

            .rc-select .rc-select-selection__choice {
                margin-right: 0;
            }

            .rc-select-selection--single .rc-select-selection__rendered {
                line-height: 32px;
            }

            .rc-select-selection--multiple .rc-select-search--inline .rc-select-search__field {
                line-height: 34px;
            }

            .rc-select-selection--multiple .rc-select-selection__rendered {
                padding-bottom: 4px;
            }

            .rc-select-selection--multiple .rc-select-selection__rendered .rc-select-selection__choice {
                margin-top: 4px;
                line-height: 32px;
                font-weight: 500;
            }

            .rc-select-selection--multiple .rc-select-selection__clear {
                top: 32%;
                cursor: pointer;
                line-height: 15px;
                right: -8px;
                font-size: 1.2rem;
                width: 30px;
                height: 30px;
                font-weight: normal;
            }

            .rc-select-search--inline .rc-select-search__field__mirror {
                padding: 16px;
            }

            .rc-select-selection__placeholder {
                font-size: 1rem;
                text-align: left;
                margin-left: 3px;
                top: 50%;
                transform: translateY(-50%);
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                width: 93%;
                height: 100%;
                line-height: 36px;

                @media screen and (max-width: 576px) {
                    font-size: 0.85rem;
                    margin-left: 3px;
                }
            }

            .rc-select-dropdown-menu-item {
                padding: 12px 10px;
            }
        }

        .form-item {
            margin-bottom: 0;

            .ant-form-explain {
                font-size: 0.75rem;
            }
        }

        .rc-select-selection__clear {
            margin-right: 0.75rem;
        }

        .rc-select-selection__clear-icon {
            font-weight: 600;
            font-size: 1.5rem;
        }
    }
}

// .SearchFilterModalMobile {
//     .advanced-search-select {
//         border-radius: 0.25rem;

//         .rc-select-selection {
//             border-radius: 0.25rem;
//             line-height: 32px;
//         }

//         .rc-select-enabled .rc-select-selection:hover {
//             border-color: unset;
//             box-shadow: none;
//         }

//         .rc-select .rc-select-selection__choice {
//             margin-right: 0;
//         }

//         .rc-select-selection--single .rc-select-selection__rendered {
//             line-height: 32px;
//         }

//         .rc-select-selection--multiple .rc-select-search--inline .rc-select-search__field {
//             line-height: 34px;
//         }

//         .rc-select-selection--multiple .rc-select-selection__rendered {
//             padding-bottom: 4px;
//         }

//         .rc-select-selection--multiple .rc-select-selection__rendered .rc-select-selection__choice {
//             margin-top: 4px;
//             line-height: 32px;
//             font-weight: 500;
//         }

//         .rc-select-selection--multiple .rc-select-selection__clear {
//             top: 32%;
//             cursor: pointer;
//             line-height: 15px;
//             right: -8px;
//             font-size: 1.2rem;
//             width: 30px;
//             height: 30px;
//             font-weight: normal;
//         }

//         .rc-select-search--inline .rc-select-search__field__mirror {
//             padding: 16px;
//         }

//         .rc-select-selection__placeholder {
//             font-size: 1rem;
//             text-align: left;
//             margin-left: 3px;
//             top: 50%;
//             transform: translateY(-50%);
//             text-overflow: ellipsis;
//             white-space: nowrap;
//             overflow: hidden;
//             width: 93%;
//             height: 100%;
//             line-height: 36px;

//             @media screen and (max-width: 576px) {
//                 font-size: 0.85rem;
//                 margin-left: 3px;
//             }
//         }

//         .rc-select-dropdown-menu-item {
//             padding: 12px 10px;
//         }
//     }

//     .form-item {
//         margin-bottom: 0;

//         .ant-form-explain {
//             font-size: 0.75rem;
//         }
//     }
// }
