.search-results .ant-list-item {
    :global {
        .ant-list-item-content {
            width: 100%;
        }
    }
}

.advance-search-map,
.advanceSearchResult {
    min-height: 400px;

    :global {
        .featured-agent-container {
            top: 0px;
            ///* Small (sm) */
            //@media (min-width: 640px) {
            //    top: 0px;
            //}
            /////* Medium (md) */
            //@media (min-width: 768px) {
            //    top: 0;
            //}
            /* Large (lg) */
            @media (min-width: 992px) {
                top: 100px;
            }

            &.scrolled {
                top: 52px;
                ///* Small (sm) */
                //@media (min-width: 640px) {
                //    top: 52px;
                //}
                /////* Medium (md) */
                //@media (min-width: 768px) {
                //    top: 52px;
                //}
                /* Large (lg) */
                @media (min-width: 992px) {
                    top: 123px;
                }
                ///* Large (lg) */
                //@media (min-width: 1024px) {
                //    top: 100px;
                //}
            }
        }

        .featured-agent-affix {
            > div {
                background: white;
                padding-top: 0.6em;
                z-index: 999;

                @media screen and (max-width: 768px) {
                    padding-top: 0;
                }
            }

            .PropertySearchFeaturedAgentCardThin {
                max-height: 0;
                overflow: hidden;
                background: white;
                opacity: 0;
                transition: 0.3s max-height ease, 0.3s opacity ease;
            }

            .ant-affix {
                .PropertySearchFeaturedAgentCardThin {
                    opacity: 1;
                    max-height: 160px;
                    overflow: auto;
                }
            }
        }

        // .advance-search-map {
        //     //display: block;
        //     right: 0px;
        //     overflow: hidden;
        //     height: calc(100vh - 124px);
        //     position: fixed;
        // }

        .advance-search {
            right: 0px;
            overflow: hidden;
            // height: calc(100vh - 124px);
            position: fixed;
            height: 100%;

            &-map {
                height: calc(100vh - 170px);
            }
            &-rightside-content {
                top: 0;
            }
        }

        .advance-search-map-container {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
        }

        .ant-tabs {
            @media (min-width: 1280px) {
                padding-left: 1.5rem;
                padding-right: 1.5rem;
            }
        }
    }
}
