.PropertySearchFeaturedAgentCard {
    width: 100%;
    height: 100%;

    :global {
        .property-carousel {
            .BrainhubCarousel,
            .BrainhubCarousel__trackContainer,
            .BrainhubCarousel__track {
                height: 100%;
            }

            .property-card-carousel {
                height: 100%;
                max-height: 12rem;
                width: 100%;
                position: relative;

                .ls-blur-up-img,
                .hero-carousel-img {
                    position: relative;
                    width: 100%;
                    height: 100%;
                    ///* only if you want to change the blur-up option from auto to always */
                    //font-family: "blur-up: always", "object-fit: cover";
                    //object-fit: cover;
                }

                .hero-carousel-img-container {
                    position: relative;
                    &:after {
                        //     -moz-box-shadow: inset 0 0 50em rgb(42, 42, 42);
                        //     -webkit-box-shadow: inset 0 0 50em rgb(42, 42, 42);
                        //     box-shadow: inset 0 0 50em rgb(42, 42, 42);
                        // background: radial-gradient( circle, transparent 50%, black 150%), radial-gradient(ellipse at center, rgba(0, 0, 0, 0.5) 0%, rgba(166, 166, 166, 0.05) 65%, rgba(255, 255, 255, 0) 100%);
                        //background: rgba(0, 0, 0, 0.5);
                        position: absolute;
                        //top: 0;
                        //left: 0;
                        //width: 100%;
                        //height: 100%;
                        //z-index: 2;
                        content: '';
                    }
                }
                *:not(.hero-details):not(.slick-dots):not(.error) {
                    height: 100%;
                    min-height: 100%;
                    object-fit: cover; // object-position: 50% 50%;
                }
            }
        }
    }

    .agent-profile-picture-enquiry {
        position: relative;

        span {
            color: #ffffff;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) !important;
        }
    }
}
