.PropertyStatusSelect {
    :global {
        .ant-select-selector {
            border: none !important;
            background: transparent;
            box-shadow: none;
            padding-left: 3px !important;
        }

        .ant-select-selector {
            .ant-select-selection-item {
                a {
                    pointer-events: none;
                }
            }
        }

        .ant-select-arrow {
            top: 60%;
            -webkit-transform: translateY(-25%);
            -moz-transform: translateY(-25%);
            transform: translateY(-25%);
        }
        .ant-select-open {
            .ant-select-arrow {
                span[role='img'] {
                    color: #3b82f6;
                    -webkit-transform: rotateY(180deg);
                    -moz-transform: rotateY(180deg);
                    transform: rotateX(180deg);
                }
            }
        }
    }
}
