.FeaturedPropertyCard {
    box-shadow: 0px 0px 10px -1px rgba(0, 0, 0, 0.2);

    //border-radius: 0.35em;

    //@media screen and (max-width: 576px) {
    //    border-radius: 0em;
    //}

    .propertyFeature {
        padding: 2px 10px;

        &:not(:last-child):not(:only-child) {
            border-right: 0;
        }
    }

    // .photos {
    //     height: 24rem;
    // }
}
