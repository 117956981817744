.advance-search-map-container {
    .property-infobox {
        // top: 0;
        left: 50%;
        bottom: 0;
        transform: translate(-50%, 0%);
    }

    :global {
        /* Outside white border */
        .asset-map-image-marker {
            background-color: #ffffff;
            border: 1px solid #3b82f6;
            border-radius: 5px;
            cursor: pointer !important;
            height: 40px;
            margin-left: 4px;
            margin-top: -26px; /* margin-top = -height + arrow */
            padding: 0px;
            position: absolute;
            width: 40px;
            -webkit-animation-duration: 0.75s;
            animation-duration: 0.75s;
            -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
            -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
            animation-iteration-count: infinite;
            -webkit-animation-iteration-count: infinite;
        }

        /* Arrow on bottom of container */
        .asset-map-image-marker:after {
            border-color: #3b82f6 transparent;
            border-style: solid;
            border-width: 10px 10px 0;
            bottom: -10px;
            content: '';
            display: block;
            left: 10px;
            position: absolute;
            width: 0;
        }

        /* Inner image container */
        .asset-map-image-marker div.image {
            background-color: #cccccc;
            background-position: center center;
            background-size: cover;
            border-radius: 5px;
            height: 36px;
            margin: 2px;
            width: 36px;
        }

        .map-marker-bounce {
            .asset-map-image-marker {
                animation-name: bounce;
            }
        }
    }
}
