.PopoverPricingFilterRange {
    .filterRangeDash {
        margin: 0;
    }

    // .ant-select-selector {
    //     border: 0 !important;
    // }
    :global {
        .ant-select-selection-item {
            text-align: center;
        }
    }
}
