.cutomSelect {
    :global {
        .ant-select:not(.ant-select-customize-input) .ant-select-selector {
            position: relative;
            background-color: #fff;
            border: 1px solid #d9d9d9;
            border-radius: 0.25rem;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        }
    }
}
