.PropertySortingSelect {
    :global {
        .rc-select .rc-select-selection {
            border-radius: 0;
        }
        .ant-select-selector {
            border: none !important;
            background: transparent;
            box-shadow: none;
            padding-left: 3px !important;
        }

        .ant-select-selector {
            font-size: 1rem;

            .ant-select-selection-item {
                a {
                    pointer-events: none;
                }
                color: #3b82f6;
            }
        }

        .ant-select-arrow {
            color: #3b82f6;
            top: 50%;
            font-size: 1rem;
            -webkit-transform: translateY(-25%);
            -moz-transform: translateY(-25%);
            transform: translateY(-25%);
        }
        .ant-select-open {
            .ant-select-arrow {
                span[role='img'] {
                    -webkit-transform: rotateY(180deg);
                    -moz-transform: rotateY(180deg);
                    transform: rotateX(180deg);
                }
            }
        }
    }
}

\:global {
    .search-options-dropdown {
        .ant-select-item-option-content {
            text-align: right;
            font-size: 1.25rem;
            font-weight: 400;

            @media (min-width: 320px) {
                font-size: 1rem;
            }

            @media (min-width: 768px) {
                font-size: 1.5rem;
            }

            @media (min-width: 1280px) {
                font-size: 15px;
            }

            a {
                color: #495264;
            }
        }

        .ant-select-item-option-selected {
            .ant-select-item-option-content {
                a {
                    color: #3b82f6;
                }
            }
        }
    }
}
