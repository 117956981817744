.agent-avatar {
    position: relative;
    width: 3rem;
    height: 3rem;

    i {
        font-size: 2em;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        @media screen and (max-width: 576px) {
            font-size: 1.5em;
        }
    }

    @media (min-width: 1024px) {
        width: 5rem;
        height: 5rem;
    }

    @media (min-width: 768) {
        width: 4rem;
        height: 4rem;
    }

    @media (min-width: 640px) {
        font-size: 2.25rem;
    }
}

.agent-profile-picture-property {
    position: relative;
    font-size: 1.875rem;
    width: 3rem;
    height: 3rem;
    background-color: #bee3f8;

    span {
        color: #ffffff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) !important;
    }

    @media (min-width: 1024px) {
        width: 5rem;
        height: 5rem;
    }

    @media (min-width: 768) {
        width: 4rem;
        height: 4rem;
    }

    @media (min-width: 640px) {
        font-size: 2.25rem;
    }
}
