.AgentEnquiryForm {
    // > div > div {
    //     background: rgb(255, 255, 255);
    //     box-shadow: 0px 0px 8px 0 rgba(141, 141, 141, 0.192);
    //     border-radius: 0.75rem;
    // }
    .agentFormWrapper {
        :global {
            .choices {
                height: 50px;
                line-height: 50px;
                border-radius: 0.5rem;
                font-weight: normal;
                transition: 0.3s all ease;
                margin-bottom: 0.5rem;

                &:before {
                    display: none;
                }

                &:hover,
                &.ant-radio-button-wrapper-checked {
                    color: #22292f;
                    border: 1px solid black;
                    box-shadow: 0px 0px 8px 0 rgba(141, 141, 141, 0.333);
                }
            }

            .form-input {
                &:hover,
                > .ant-input:hover {
                    border-color: #adb3b4;
                }

                .ant-input:focus {
                    border-color: #c3c9ca;
                    box-shadow: 0 0 0 2px rgba(108, 114, 116, 0.2);
                }
            }

            .choices,
            input {
                border: 1px solid #dae1e7;
                border-left: 1px solid #dae1e7;
            }

            button {
                border: 0;
            }
        }
    }
}
