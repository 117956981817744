.SearchPage {
    .content {
        width: 100%;
        top: 104px;
        bottom: 0px;
        left: 0px;
        right: 0px;

        /* Medium (md) */
        // @media (min-width: 992px) {
        //     position: absolute;
        //     top: 104px;
        //     bottom: 0px;
        //     left: 0px;
        //     right: 0px;
        //     // position: -webkit-sticky;
        // }
    }

    :global {
        .default-theme .header {
            //position: sticky;
            position: static;
            //position: -webkit-sticky;
            top: 0;
            //height: 56px;
            //max-height: 56px;
            z-index: 10;
            width: 100%;

            /* Small (sm) */
            @media (min-width: 640px) {
                position: static;
            }
            /* Medium (md) */
            @media (min-width: 768px) {
                position: static;
            }

            /* Medium (md) */
            @media (min-width: 992px) {
                position: sticky;
                position: -webkit-sticky;
            }

            ///* Large (lg) */
            //@media (min-width: 1024px) {
            //    position: sticky;
            //    position: -webkit-sticky;
            //}
        }
    }

    .contentWrapper {
        padding-top: 0px;
    }

    .searchResultsWrapper {
        position: relative;
        width: 100%;
        z-index: 0;
        min-height: 400px;
        padding-top: 0;

        //@media (max-width: 575px) {
        //    padding-top: 0;
        //}

        /* Small (sm) */
        //@media (min-width: 640px) {
        //    padding-top: 0
        //}
        //
        /////* Medium (md) */
        //@media (min-width: 768px) {
        //    padding-top: 0;
        //}

        /* Large (lg) */
        @media (min-width: 992px) {
            padding-top: 60px;
        }

        ///* Large (lg) */
        //@media (min-width: 1024px) {
        //    padding-top: 0;
        //}
    }

    .searchFilter {
        background: #ffffff;
        position: static;
        -webkit-transition: -webkit-transform 200ms linear, transform 200ms linear;
        -moz-transition: transform 200ms linear;
        transition: -ms-transform 200ms linear, -webkit-transform 200ms linear, transform 200ms linear;
        z-index: 9;
        border-bottom: 1px solid #ebebeb;
        width: 100%;
        max-height: 76px;
        // padding-bottom: 2rem;

        @media (min-width: 768px) {
            position: static;
        }

        /* Large (lg) */
        @media (min-width: 992px) {
            position: fixed;
        }

        //@media screen and (max-width: 992px) {
        //    max-height: 106px;
        //}
        //
        //@media screen and (max-width: 575px) {
        //    position: static;
        //}
    }
}
