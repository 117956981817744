.advancedSearch {
    background: white;
    border-top: 1px solid #d8d8d8;
    // display: flex;
    // flex-flow: row nowrap !important;

    :global {
        .ant-row {
            flex-flow: row nowrap;
        }

        .advanced-search-select {
            border-radius: 0.25rem;
            height: 49px;
            max-height: 49px;
            overflow: hidden;

            @media screen and (min-width: 992px) {
                border-radius: 0.25rem 0 0 0.25rem;
                border-right: 0;
            }

            .ant-select-selector {
                border: 0;
            }
            .rc-select-selection {
                border: 0;
                border-radius: 0.25rem;
                line-height: 32px;
            }

            .rc-select-enabled .rc-select-selection:hover {
                border-color: unset;
                box-shadow: none;
            }

            .rc-select .rc-select-selection__choice {
                margin-right: 4px;
            }

            .rc-select-selection--single .rc-select-selection__rendered {
                line-height: 32px;
            }

            .rc-select-selection--multiple {
                position: relative;
                max-height: 50px;
                height: 50px;

                > :first-child {
                    height: 50px;
                    max-height: 50px;
                    overflow-y: auto;
                }

                .rc-select-selection__clear {
                    cursor: pointer;
                    line-height: 15px;
                    right: 3px;
                    font-size: 1.4rem;
                    position: absolute;
                    top: 15px;
                    right: 20px;
                }

                .rc-select-selection__rendered .rc-select-selection__choice {
                    margin-top: 9px;
                    line-height: 32px;
                    font-weight: 500;
                    background-color: #ffffff;
                    border: 1px solid #e5f3f7;
                    // font-family: 'Poppins', sans-serif;
                }

                .rc-select-search--inline {
                    max-height: 48px;

                    .rc-select-search__field {
                        height: 50px;
                        line-height: 50px;

                        .rc-select-search__field__mirror {
                            padding: 16px;
                        }
                    }
                }
            }

            .rc-select-selection__placeholder {
                font-size: 1rem;
                text-align: left;
                margin-left: 3px;
                top: 54%;
                transform: translateY(-50%);
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                width: 96%;
                height: 100%;
                max-height: 35px;

                @media screen and (max-width: 576px) {
                    font-size: 0.85rem;
                    margin-left: 3px;
                }
            }

            .rc-select-dropdown-menu-item {
                padding: 12px 10px;
            }

            span.ant-form-item-children {
                display: block;
                width: 100%;
                height: 50px;
            }
        }

        .form-item {
            margin-bottom: 0;
        }
    }

    .searchInput {
        :global {
            .ant-select,
            .ant-select:focus,
            .ant-select:hover,
            .ant-select-selection,
            .keyword-input:focus {
                border: 0;
                max-height: 48px;
                line-height: 48px;
                border-bottom-right-radius: 0;
                border-top-right-radius: 0;
                //overflow-y: auto;
            }

            .ant-select-search__field__mirror {
                padding: 15px;
            }

            .ant-select-selection__placeholder {
                margin-left: 10px;
            }

            .ant-select-selection__rendered {
                li.ant-select-selection__choice {
                    height: 40px;
                    line-height: 40px;
                }

                li {
                    height: 36px;
                    line-height: 36px;
                    font-weight: 800;
                    font-size: 17px;
                }

                .ant-select-selection__placeholder {
                    margin-top: -8px;
                    color: #8795a1;
                }
            }

            .keyword-search {
                transition: 0.5s ease all;

                .search-btn-checkbox {
                    label.ant-checkbox-wrapper {
                        span:nth-child(-n + 3) {
                            padding-left: 0px;
                            padding-right: 2px;
                        }
                    }
                }

                > div {
                    border: none;

                    div {
                        background: transparent;
                    }
                }

                &.search-error {
                    -webkit-box-shadow: 0px 0px 0px 2px rgba(255, 98, 98, 1);
                    -moz-box-shadow: 0px 0px 0px 2px rgba(255, 98, 98, 1);
                    box-shadow: 0px 0px 0px 2px rgba(255, 98, 98, 1);

                    @media screen and (max-width: 768px) {
                        -webkit-box-shadow: none;
                        -moz-box-shadow: none;
                        box-shadow: none;
                    }

                    .keyword-input {
                        .rc-select-selection__placeholder {
                            color: #d86779;
                        }
                    }
                }

                // Change AntD PropertyAdvancedSearch flex settings and @media screens
                // @media (min-width: 1600px) {
                //     display: block;
                //     flex: 0 0 37.5%;
                //     max-width: 37.5%;
                // }

                // @media (min-width: 1200px) {
                //     display: block;
                //     flex: 0 0 41.66666667%;
                //     max-width: 41.66666667%;
                // }

                // @media (min-width: 768px) {
                //     display: block;
                // flex: 0 0
                // max-width:
                // }
            }

            .keyword,
            .find-agent {
                > div {
                    border-radius: 0;
                    height: 50px;
                    line-height: 50px;
                    border-bottom-left-radius: 0.25rem;
                    border-top-left-radius: 0.25rem;
                    border: 0;

                    @media screen and (max-width: 576px) {
                        line-height: 30px;
                        height: 30px;
                    }

                    > div {
                        height: 50px;
                        line-height: 50px;

                        @media screen and (max-width: 576px) {
                            line-height: 30px;
                            height: 30px;
                        }

                        .keyword-input {
                            line-height: 50px;
                            height: 50px;

                            @media screen and (max-width: 576px) {
                                line-height: 30px;
                                height: 30px;
                            }

                            &:focus,
                            &:hover {
                                border: 0;
                                box-shadow: 0px 0px 4px 2px rgba(167, 239, 255, 0.558);
                            }
                        }
                    }
                }
            }
        }
    }

    :global {
        .find-agent-keyword {
            > div {
                border-bottom-left-radius: 0px;
                border-top-left-radius: 0px;
            }
        }

        .search-option {
            &:not(:last-child) {
                border-right: 0.5px solid rgba(205, 205, 205, 0.53);
            }

            > div > div {
                line-height: 20px;
            }
        }
    }
}

\:global {
    .search-btn-cont {
        height: 48px;
        line-height: 48px;

        @media screen and (max-width: 576px) {
            line-height: 48px;
            height: 48px;
        }

        .search-btn {
            position: relative;
            border-width: 20px;
            border: 0 solid transparent;
            border-color: palegoldenrod;
            line-height: 35px;
            height: 35px;
        }

        .search-btn .anticon-search {
            font-size: 1.5em;
        }
    }

    .search-filters {
        @media screen and (max-width: 576px) {
            line-height: 30px;
            height: 30px;
        }
    }

    .search-type-list {
        border-color: transparent;
        box-sizing: border-box;

        @media screen and (max-width: 576px) {
            height: 3em;
        }

        .search-type {
            // font-family: 'Poppins', sans-serif;
            margin-right: 0.1em;
            font-weight: 900;
            font-style: normal;

            > a {
                color: white;
                font-size: 20px;

                @media screen and (max-width: 576px) {
                    font-size: 14px;
                    line-height: 3;
                }
            }

            &.ant-menu-item-selected {
                border-bottom: 2px solid rgba(255, 255, 255, 0);

                &:after {
                    content: '';
                    width: 100%;
                    height: 5px;
                    border-radius: 99px;
                    background: #3b82f6;
                    position: absolute;
                    display: block;
                    bottom: 0;
                    left: 50%;
                    transform: translateX(-50%);
                }

                > a {
                    color: white;
                }
            }

            &:not(.ant-menu-item-selected) {
                border-color: transparent;
            }

            &:hover {
                > a {
                    color: white;
                }

                &:after {
                    content: '';
                    width: 100%;
                    height: 5px;
                    border-radius: 99px;
                    background: #3b82f6;
                    position: absolute;
                    display: block;
                    bottom: 0;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
        }
    }
}

.numberInput {
    position: relative;
    padding: 0px 7px;
    height: 50px;

    > div {
        &:last-child {
            margin-top: 0px;
            box-shadow: none;
            border: none;
            padding-right: 0;
        }

        button {
            background: #fff !important;
        }
    }
}
